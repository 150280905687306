import { createGlobalStyle } from "styled-components"

import PropTypes from "prop-types"

import "sanitize.css"

const Styles = createGlobalStyle`
  body {
    font-family: "FigTree", sans-serif;
    -webkit-font-smoothing: antialiased; /* Chrome, Safari */
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: inherit;
    text-decoration: underline;
    font-weight: 600;
  }
`

const GlobalStyles = ({ children }) => {
  return (
    <>
      <Styles />
      {children}
    </>
  )
}

GlobalStyles.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GlobalStyles
